<template lang="pug">
section.section.analyze-screen.container
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .inner(v-if="!initializing && isFreePlan")
    .columns
      .column.is-12.header-flex
        h1.title Upgrade your Plan
    .columns
      .column
        .box
          .content
            p Sorry, our sharing features are only available on our paid plans.
            p
              | Should you upgrade, you'll be able to export high quality PNG images of
              | charts with extra customisation options around colour and what
              | is displayed. You can pick what currency you want to share in
              | and everything will convert to match your selection.
            p
              | You'll also get access to embeddable HTML chart widgets which you
              | can copy and paste into your blog or website. Unlike regular PNG images,
              | these interactive widgets scale smoothly with the browser width
              | and provide a great experience to your viewers.
          router-link.button.is-large.is-primary(:to='{name: "billing_plan"}') Upgrade today
  .inner(v-if="!initializing && !isFreePlan")
    .columns
      .column.is-12.header-flex
        h1.title Share
        display-currency-select(prefix="Sharing in")
    .columns
      .column
        .box
          b-field(label="Share to Date")
            b-datepicker(v-model="date" :date-parser="dateParser" :date-formatter="dateFormatter" :max-date="today")
          b-field(label="Visualisation Type")
            b-select(v-model="shareType" placeholder="Choose...")
              option(value="pie") Asset Breakdown Pie
              option(value="area") Area Chart
              option(value="bar") Stacked Bar Chart
    .columns(v-if="shareType == 'area'")
      .column
        .box
          h2.subtitle Configure
          b-field(label="Dataset")
            b-select(v-model="area.mode" placeholder="Choose...")
              optgroup(label="Aggregate Data")
                option(value="networth") Net Worth
                option(value="assets") Net Assets
                option(value="liabilities") Net Liabilities
              optgroup(label="Categories")
                option(:value="'category:'+category.id" :key='category.id' v-for='category in orderedCategories') {{category.name}}
          b-field(label="Share from Date")
            b-datepicker(v-model="startDate" :date-parser="dateParser" :date-formatter="dateFormatter" :max-date="today")
          b-field(label="Group by")
            b-select(v-model="groupBy" placeholder="Choose...")
              option(value="day") Day
              option(value="month") Month
              option(value="year") Year
          b-field(label="Chart color")
            v-swatches(v-model="area.backgroundColor" inline)
          b-field(label="Download")
          b-field.responsive-buttonset
            .control
              b-button(@click="exportAreaChartPNG(640, 480)") 640x480 PNG
            .control
              b-button(@click="exportAreaChartPNG(1024, 480)") 1024x480 PNG
            .control
              b-button(@click="exportAreaChartPNG(1280, 480)") 1280x480 PNG
            .control
              b-button(@click="showAreaEmbedCode()") HTML Embed
        .box
          h2.subtitle Preview
          loading-wrapper
            compound-area(:mode="area.mode" :start-date="startDate" :end-date="date" :group-by="groupBy" :background-color="area.backgroundColor" ref='areaChart')

    .columns(v-if="shareType == 'bar'")
      .column
        .box
          h2.subtitle Configure
          b-field(label="Enabled Categories")
          .category-disable(v-for="category in orderedCategories" :key="category.id")
            b-checkbox(:value="!bar.disabled[category.id]" @input="toggleBar(category.id, $event)") {{category.name}} ({{category | getTypeName}})
          b-field(label="Share from Date")
            b-datepicker(v-model="startDate" :date-parser="dateParser" :date-formatter="dateFormatter" :max-date="today")
          b-field(label="Group by")
            b-select(v-model="groupBy" placeholder="Choose...")
              option(value="day") Day
              option(value="month") Month
              option(value="year") Year
          b-field(label="Download")
          b-field.responsive-buttonset
            .control
              b-button(@click="exportBarChartPNG(640, 480)") 640x480 PNG
            .control
              b-button(@click="exportBarChartPNG(1024, 480)") 1024x480 PNG
            .control
              b-button(@click="exportBarChartPNG(1280, 480)") 1280x480 PNG
            .control
              b-button(@click="showBarEmbedCode()") HTML Embed
        .box
          h2.subtitle Preview
          loading-wrapper
            breakdown-chart(:start-date="startDate" :end-date="date" :group-by="groupBy" ref='barChart' :disable="bar.disabled")

    .columns(v-if="shareType == 'pie'")
      .column
        .box
          h2.subtitle Configure
          b-radio(v-model="pie.mode" name="mode" native-value="assets") Assets
          b-radio(v-model="pie.mode" name="mode" native-value="liabilities") Liabilities
          b-field(label="Enabled Categories")
          template(v-if="pie.mode === 'assets'")
            .category-disable(v-for="category in orderedAssets" :key="category.id")
              b-checkbox(:value="!pie.disabled[category.id]" @input="togglePie(category.id, $event)") {{category.name}} ({{category | getTypeName}})
          template(v-else)
            .category-disable(v-for="category in orderedLiabilities" :key="category.id")
              b-checkbox(:value="!pie.disabled[category.id]" @input="togglePie(category.id, $event)") {{category.name}} ({{category | getTypeName}})
          b-field(label="Download")
          b-field.responsive-buttonset
            .control
              b-button(@click="exportPieChartPNG(640, 480)") 640x480 PNG
            .control
              b-button(@click="exportPieChartPNG(1024, 480)") 1024x480 PNG
            .control
              b-button(@click="exportPieChartPNG(1280, 480)") 1280x480 PNG
            .control
              b-button(@click="showPieEmbedCode()") HTML Embed
        .box
          h2.subtitle Preview
          loading-wrapper
            breakdown-pie(:date="date" ref='pieChart' :mode='pie.mode' :disable="pie.disabled")

</template>
<script>
import VSwatches from 'vue-swatches'
import { mapState, mapGetters } from 'vuex'
import dayjs from '@/dayjs'
import { formatDate } from '@/misc/filters'
import { dateParser } from '@/misc/helpers'
import LoadingWrapper from '@/components/LoadingWrapper.vue'
import DisplayCurrencySelect from '@/components/DisplayCurrencySelect.vue'
import CompoundArea from '@/components/charts/CompoundArea.vue'
import BreakdownChart from '@/components/charts/BreakdownChart.vue'
import BreakdownPie from '@/components/charts/BreakdownPie.vue'
import { getTypeName } from '@/misc/type_map'

import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'ShareView',
  components: {
    BreakdownChart,
    BreakdownPie,
    DisplayCurrencySelect,
    CompoundArea,
    LoadingWrapper,
    VSwatches
  },
  filters: {
    formatDate,
    getTypeName(category) {
      let typeId = category.type
      if (typeId === 'custom') typeId = category.customName
      return getTypeName(typeId)
    }
  },
  data() {
    const today = dayjs()
      .startOf('day')
      .toDate()
    return {
      date: dayjs(today).toDate(),
      today,
      shareType: null,
      area: {
        mode: 'networth',
        backgroundColor: null
      },
      bar: {
        disabled: {}
      },
      pie: {
        mode: 'assets',
        disabled: {}
      },
      startDate: dayjs(today)
        .startOf('year')
        .subtract(1, 'year')
        .toDate(),
      groupBy: 'month'
    }
  },
  computed: {
    ...mapState('currencies', ['loadingCurrencies']),
    ...mapState('entries', ['entries', 'loadingEntries']),
    ...mapState('categories', ['loadingCategories']),
    ...mapGetters('authentication', ['isFreePlan']),
    ...mapGetters('categories', ['orderedCategories']),
    initializing() {
      return (
        this.loadingCurrencies || this.loadingEntries || this.loadingCategories
      )
    },
    orderedAssets() {
      const { orderedCategories } = this
      return orderedCategories.filter(({ isAsset }) => isAsset)
    },
    orderedLiabilities() {
      const { orderedCategories } = this
      return orderedCategories.filter(({ isAsset }) => !isAsset)
    }
  },
  methods: {
    dateParser,
    dateFormatter: formatDate,
    exportAreaChartPNG(w, h) {
      this.$refs.areaChart.exportPNG(w, h)
    },
    exportBarChartPNG(w, h) {
      this.$refs.barChart.exportPNG(w, h)
    },
    exportPieChartPNG(w, h) {
      this.$refs.pieChart.exportPNG(w, h)
    },
    toggleBar(categoryId, value) {
      this.bar.disabled[categoryId] = !value
      this.$refs.barChart.recompute()
    },
    togglePie(categoryId, value) {
      this.pie.disabled[categoryId] = !value
      this.$refs.pieChart.recompute()
    },
    generateEmbedCode(embedType, data) {
      const uri = `${window.location.origin}/embed/${embedType}`
      const uid = (+Date.now()).toString()
      const script = `<script>
        window.nutworth_${uid} = function() {
          document.getElementById('nutworth-${uid}').contentWindow.postMessage({d: ${JSON.stringify(
        data
      )}, m: 'chart', v: 1}, ${JSON.stringify(window.location.origin)});
        }
        <\/script>`.replace(/^ */gm, '') // eslint-disable-line
      return `<iframe id='nutworth-${uid}' src="${uri}" width="100%" height="450px" frameborder="0" onload='nutworth_${uid}()'></iframe>${script}`
    },
    showAreaEmbedCode() {
      const data = this.$refs.areaChart.embedData()
      const embedCode = this.generateEmbedCode('area', data)
      this.$buefy.dialog.alert({
        title: 'Embed Code',
        message: `<textarea id='embedarea'>${embedCode}</textarea>`
      })
    },
    showBarEmbedCode() {
      const data = this.$refs.barChart.embedData()
      const embedCode = this.generateEmbedCode('bar', data)
      this.$buefy.dialog.alert({
        title: 'Embed Code',
        message: `<textarea id='embedarea'>${embedCode}</textarea>`
      })
    },
    showPieEmbedCode() {
      const data = this.$refs.pieChart.embedData()
      const embedCode = this.generateEmbedCode('pie', data)
      this.$buefy.dialog.alert({
        title: 'Embed Code',
        message: `<textarea id='embedarea'>${embedCode}</textarea>`
      })
    }
  },
  metaInfo: {
    title: 'Share your Progress'
  }
}
</script>
<style lang="sass" scoped>
.header-flex
  display: flex
  flex-direction: row
  flex-wrap: wrap
  .title
    flex-grow: 1
    white-space: nowrap
  .currency-select
    margin-right: 0.5rem
.column
  max-width: 100%
.responsive-buttonset ::v-deep .has-addons
  flex-wrap: wrap
@media screen and (max-width: 600px)
  .header-flex
    .title
      width: auto
      order: 0
    .multiselect
      max-width: 100%
      margin-right: 0
      order: 2
</style>
<style lang="sass">
#embedarea
  width: 100%
  height: 8rem
  background: #f0f0f0
  font-family: monospace
  padding: 0.5rem
  overflow-y: scroll
</style>
